<template>
<div>
  <b-img
      :src="skin == 'light' ? appLogoImage : appBlackLogoImage"
      alt="logo"
      width="150px"
    />
</div>
</template>

<script>
import {  BImg } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'
import useAppConfig from '@core/app-config/useAppConfig'

export default {
  components: {
    BImg,
  },
  setup() {
    const { skin } = useAppConfig()

    // App Name
    const { appLogoImage, appBlackLogoImage } = $themeConfig.app
    return {
      appLogoImage,
      appBlackLogoImage,
      skin
    }
  },
}
</script>
